@font-face {
    font-family: 'Futura PT Light';
    src: url('../fonts/FuturaPT-Light.eot');
    src: url('../fonts/FuturaPT-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/FuturaPT-Light.woff') format('woff'),
    url('../fonts/FuturaPT-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans Light';
    src: url('../fonts/OpenSans-Light.eot');
    src: url('../fonts/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans-Light.woff') format('woff'),
    url('../fonts/OpenSans-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans Regular';
    src: url('../fonts/OpenSans-Regular.eot');
    src: url('../fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans-Regular.woff') format('woff'),
    url('../fonts/OpenSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT Book';
    src: url('../fonts/FuturaPT-Book.eot');
    src: url('../fonts/FuturaPT-Book.eot?#iefix') format('embedded-opentype'),
    url('../fonts/FuturaPT-Book.woff') format('woff'),
    url('../fonts/FuturaPT-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans Semibold';
    src: url('../fonts/OpenSans-SemiBold.eot');
    src: url('../fonts/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans-SemiBold.woff') format('woff'),
    url('../fonts/OpenSans-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lobster Regular';
    src: url('../fonts/Lobster.eot');
    src: url('../fonts/Lobster.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Lobster.woff') format('woff'),
    url('../fonts/Lobster.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Regular';
    src: url('../fonts/Roboto-Regular.eot');
    src: url('../fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Roboto-Regular.woff') format('woff'),
    url('../fonts/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Nexa Script Bold';
    src: url('../fonts/NexaScript-Bold.eot');
    src: url('../fonts/NexaScript-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/NexaScript-Bold.woff') format('woff'),
    url('../fonts/NexaScript-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}