@import 'normalize';

@import 'variebles';

@import 'fonts';
@import 'responce';
@import 'header';
@import 'home';
@import 'footer';

body {
    overflow-x: hidden;
}

.container {
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
    padding: 0 15px;
}

.section-title {
    font-family: $fontFuturaBook;
    font-size: 40px;
    color: #531230;
    text-align: center;
    margin-bottom: 50px;
    @include mediaw(480px) {
        font-size: 27px;    
        line-height: 35px;
        margin-bottom: 35px;
    }
}

.price__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 857px;
    margin: 60px auto 0;
    border-radius: 16px;
    background-color: #ffe0ee;
    padding: 20px 40px;
    @include mediaw(460px) {
        padding: 10px 20px;
    }
    @include mediaw(480px) {
        margin: 30px auto 0;
    }
    &-block {
        width: 490px;
        height: 60px;
        color: #606060;
        display: flex;
        align-items: center;
        justify-content: center;
        @include mediaw(600px) {
            width: 50%;
        }
        // @include mediaw(480px) {
        //     width: 40%;
        // }
        .old, .new {
            font-family: $fontOpenSansRegular;
            font-size: 20px;
            line-height: 24px;
            @include mediaw(360px) {
                font-size: 14px;
                line-height: 22px;
            }
            span {
                font-size: 28px;
                line-height: 40px;
                display: inline-block;
                margin-top: 10px;
                @include mediaw(399px) {
                    font-size: 21px;
                }
            }
        }
        .old {
            span {
                position: relative;
                font-family: $fontFuturaLight;
                &::after {
                    content: " ";
                    display: block;
                    height: 2px;
                    width: 100%;
                    position: absolute;
                    top: 22px;
                    left: 0;
                    background-color: #f75252;
                }
            }
        }
        .new {
            span {
                font-family: $fontFuturaBook;
            }
        }
    }
    .price__order {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        width: 260px;
        height: 50px;
        border-radius: 5px;
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.16);
        background-color: #f21d80;
        font-family: $fontOpenSansRegular;
        font-size: 20px;
        line-height: 40px;
        @include mediaw(600px) {
            width: 50%;
        }
        @include mediaw(460px) {
            font-size: 17px;
        }
        @include mediaw(374px) {
            font-size: 16px;
        }
        @include mediaw(349px) {
            font-size: 14px;
        }
    }
}